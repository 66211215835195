'use client';

import { Box } from '@mantine/core';

import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { SelectedPage } from '@/types/types';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { RootBox } from '@/shared/RootBox';
import { SectionTitle } from '@/shared/SectionTitle';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { useParallax } from 'react-scroll-parallax';
import { AboutContent, AboutItemType } from '@/types/contentTypes';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mantine/hooks';

const ElementGlobeWhite = dynamic(() => import('@/../public/abstractShapes/elementGlobeWhite.svg'), { ssr: false });

type AboutProps = {
  content: AboutContent;
};

const AboutItem = ({ description, index }: AboutItemType) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <Box
      ref={ref}
      style={{
        animationDuration: `${index && 500 + index * 50}ms`,
      }}
      className={`flex justify-center items-start w-full overflow-visible transition-opacity duration-500 delay-${index && index * 50} ${inView ? 'opacity-100 animate-fadeInRight' : 'opacity-0'
        }`}
      key={index}
    >
      <Box className='flex w-6 h-6 text-primary-500 items-center justify-center translate-y-1.5'>
        <ArrowRightCircleIcon />
      </Box>
      <p className='flex text-secondary-500 dark:text-lightBlue-200 text-2xl font-base xxs:max-md:font-base h-full justify-start items-center leading-wider ml-4 w-full leading-snug overflow-visible '>
        {description}
      </p>
    </Box>
  );
};

export const About = ({ content }: AboutProps) => {
  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');
  const dispatch = useAppDispatch();
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(setSelectedPage(SelectedPage.Products));
    }
  }, [inView, dispatch]);

  const { ref: bgMeshRef } = useParallax<HTMLDivElement>({
    speed: 2,
    rotate: [170, 185],
    translateX: [-20, 20],
    translateY: [-30, 50],
    scale: [0.8, 1],
  });

  return (
    <Box className='relative w-full z-0' id='about' ref={ref} style={{ overflow: 'visible' }}>
      <RootBox>
        {isOnLargeScreen ? <div
          ref={bgMeshRef}
          className='xxs:max-md:hidden'
          style={{
            position: 'absolute',
            width: '20%',
            height: '20%',
            zIndex: 1,
            right: 200,
            rotate: '180deg',
            overflow: 'visible',
          }}
        >
          <Box className='opacity-15 w-full h-full xxs:max-md:hidden'>
            <ElementGlobeWhite />
          </Box>
        </div> :
          <div ref={bgMeshRef} style={{
            position: 'absolute',
          }}></div>}
        <SectionTitle title='What Is Arcanis?' index={1} />
        <Box className='flex flex-col gap-8 mt-12'>
          {content.map(({ description, Icon }, index) => (
            <AboutItem description={description} index={index} Icon={Icon} key={index} />
          ))}
          <p className='text-sm text-secondary-500/60 dark:text-lightBlue-500/60'>
            * GLS - Growth and Late Stage: private, VC-backed companies with min
            1 investment not less than 15M$+
          </p>
        </Box>
      </RootBox>
    </Box>
  );
};