import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { theme } from '@/data/nivoThemeData';
import { lightTheme } from '@/data/nivoLightThemeData';
import {
  setActiveIndex,
  clearActiveIndex,
} from '@/features/activeChartSerie/activeChartSerie';
import { LineChartData } from '@/types/chartTypes';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import { Box } from '@mantine/core';
import dynamic from 'next/dynamic';

const Tooltip = dynamic(
  () => import('./Tooltip').then((mod) => mod.Tooltip),
  { ssr: false }
);


type LineChartProps = {
  chartData: LineChartData;
  tickValues: string[];
  isOnLargeScreen: boolean | undefined;
};

export const ResponsiveLineChartComponent = ({
  chartData,
  isOnLargeScreen,
  tickValues,
}: LineChartProps) => {
  const dispatch = useAppDispatch();
  const appTheme = useAppSelector((state) => state.theme.theme);
  const topMargin = isOnLargeScreen ? 40 : 30;
  const leftMargin = isOnLargeScreen ? 60 : 40;
  const rightMargin = isOnLargeScreen ? 40 : 40;
  const bottomMargin = isOnLargeScreen ? 40 : 60;
  const xLegend = isOnLargeScreen ? 'Return (x, Cash-on-Cash)' : '';
  return (
    <ResponsiveLine
      theme={appTheme === 'light' ? lightTheme : theme}
      lineWidth={3}
      colors={{ datum: 'color' }}
      data={chartData}
      curve='natural'
      margin={{ top: topMargin, right: rightMargin, bottom: bottomMargin, left: leftMargin }}
      enableGridX={false}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat=' >-.2f'
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 10,
        tickPadding: 10,
        tickRotation: 0,
        tickValues: tickValues,
        legend: '',
        legendOffset: 90,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 5,
        legend: xLegend,
        legendOffset: -50,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      pointSize={0}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel='data.yFormatted'
      pointLabelYOffset={-12}
      enableCrosshair={true}
      enableTouchCrosshair={true}
      useMesh={true}
      enableArea={true}
      areaBaselineValue={1}
      areaOpacity={0.1}
      onMouseLeave={() => dispatch(clearActiveIndex())}
      tooltip={({ point }) => {
        const currentPoint = JSON.stringify(point.serieId).slice(1, -1);
        dispatch(setActiveIndex(currentPoint));
        return (
          <Tooltip pointX={JSON.stringify(point.data.x)} pointY={JSON.stringify(point.data.y)} serieId={point.serieId}/>
        );
      }}
    />
  );
};
