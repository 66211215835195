'use client';

import { Button } from '@mantine/core';
import React from 'react';

type StandardButtonProps = {
  children?: React.ReactNode;
  onClick: () => void;
  disabled: boolean;
  size?: string;
};

export const StandardButton = ({ children = 'Button', onClick, disabled = false, size = 'md' }: StandardButtonProps) => {
  return (
    <Button
      variant='gradient'
      gradient={{ from: '#ccb162', to: '#bf9d3b', deg: 40 }}
      radius={8}
      onClick={onClick}
      disabled={disabled}
      size={size}
    >
      {children}
    </Button>
  );
};
