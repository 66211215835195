'use client';

import { Box } from '@mantine/core';
import React, { useEffect } from 'react';
import { TeamMembers, CoreTeam } from '@/types/contentTypes';
import { RootBox } from '@/shared/RootBox';
import { SectionTitle } from '@/shared/SectionTitle';
import { useInView } from 'react-intersection-observer';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { SelectedPage } from '@/types/types';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { useMediaQuery } from '@mantine/hooks';
import dynamic from 'next/dynamic';

const CoreTeamComponent = dynamic(() => import('./CoreTeamComponent'), {
  ssr: false,
});
const PartnersComponent = dynamic(() => import('./PartnersComponent'), {
  ssr: false,
});
const Experience = dynamic(
  () => import('./Experience').then((mod) => mod.Experience),
  { ssr: false }
);
const ExperienceMobile = dynamic(
  () => import('./ExperienceMobile').then((mod) => mod.ExperienceMobile),
  { ssr: false }
);

type TeamProps = {
  partners: TeamMembers;
  coreTeam: CoreTeam;
};

const Team = React.memo(({ partners, coreTeam }: TeamProps) => {
  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');
  const dispatch = useAppDispatch();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      /* nprogress.set(90); */
      dispatch(setSelectedPage(SelectedPage.Team));
    }
  }, [inView, dispatch]);

  return (
    <Box id='team' ref={ref} className='w-full h-full'>
      <RootBox>
        <SectionTitle title='Team' index={7} />
        <p className='text-3xl text-secondary-500 dark:text-lightBlue-200 font-bold mt-12'>
          Experience
        </p>
        <Box className='gap-3 mt-8'>
          {isOnLargeScreen ? (
            <Experience partners={partners} />
          ) : (
            <ExperienceMobile partners={partners} />
          )}
        </Box>
        <p className='text-3xl text-secondary-500 dark:text-lightBlue-200 font-bold mt-20'>Partners</p>
        <PartnersComponent partners={partners} />
        <p className='text-3xl text-secondary-500 dark:text-lightBlue-200 font-bold mt-20 '>
          Core team
        </p>
        <CoreTeamComponent coreTeam={coreTeam} />
      </RootBox>
    </Box>
  );
});

Team.displayName = 'Team';

export { Team };
