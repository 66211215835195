'use client';

import { CompaniesIndexesByYear } from '@/types/contentTypes';
import { CountryCode } from '@/types/types';

export const indexHoldersData: CompaniesIndexesByYear = {
  years: [
    {
      year: 2023,
      quarters: [
        {
          quarter: 3,
          items: [
            {
              title: 'Farcaster',
              index: 5,
              logo: '/strategyHoldersCompanies/2023/3/farcaster/logo.png',
              country: 'United States',
              businessType: 'Web3',
              countryCode: CountryCode.UnitedStates,
              website: 'www.farcaster.xyz',
              companyDescription:
                "Operator of a decentralized social network intended to move identity between applications. The company's network specializes in creating an open protocol that can support many clients just like email where users can claim a unique username, off-chain hosts and can store their social data and developers have the freedom to build applications with new features on the network, enabling users to own and control their social graph.",
              ValuationTrack:
                '/strategyHoldersCompanies/2023/3/farcaster/valuation_track.svg',
            },
            {
              title: 'GreenOnyx',
              index: 4,
              logo: '/strategyHoldersCompanies/2023/3/greenonyx/logo.png',
              country: 'Israel',
              businessType: 'FoodTech',
              countryCode: CountryCode.Israel,
              website: 'www.greenonyx.ag',
              companyDescription:
                "Operator of deep-tech modular farms intended to transform everyone's diet by providing wholesome raw nutrients with the ease of dietary supplements. The company's farming is based on a deep-tech, patented cultivation, an artificial intelligence-based platform that bio-mimicks the natural habitat growth conditions and boosts the production efficiency of the green vegetable field, providing consumers with fresh food sources that have high nutrition per calorie and strong protection against chronic and infectious diseases.",
              ValuationTrack:
                '/strategyHoldersCompanies/2023/3/greenonyx/valuation_track.svg',
            },
            {
              title: 'Kumo',
              index: 7,
              logo: '/strategyHoldersCompanies/2023/3/kumo/logo.png',
              country: 'United States',
              businessType: 'AI Data Cloud',
              countryCode: CountryCode.UnitedStates,
              website: 'www.kumo.ai',
              companyDescription:
                "Developer of graph machine learning-centered AI platform designed to manage large-scale data warehouses. The company's platform aims to integrate machine learning between modern cloud data warehouses and artificial intelligence algorithms infrastructure to simplify the training and deployment of models on structured data, enabling businesses to make faster, simpler, and more accurate predictions.",
              ValuationTrack:
                '/strategyHoldersCompanies/2023/3/kumo/valuation_track.svg',
            },
            {
              title: 'LemFi',
              index: 14,
              logo: '/strategyHoldersCompanies/2023/3/lemfi/logo.png',
              country: 'United Kingdom',
              businessType: 'NeoBanking',
              countryCode: CountryCode.UnitedKingdom,
              website: 'www.lemfi.com',
              companyDescription:
                "Developer of digital banking platform designed for immigrants. The company's platform offers zero transfer fees, multi-currency support, and the ability to receive money, international money transfers without a bank account, enabling immigrants and individuals with global connections to send and receive money securely, bypassing traditional high fees, and simplifying the process.",
              ValuationTrack:
                '/strategyHoldersCompanies/2023/3/lemfi/valuation_track.svg',
            },
            {
              title: 'Typeface',
              index: 1,
              logo: '/strategyHoldersCompanies/2023/3/typeface/logo.png',
              country: 'United States',
              businessType: 'GenAI',
              countryCode: CountryCode.UnitedStates,
              website: 'www.typeface.ai',
              companyDescription:
                'Operator of an enterprise-grade generative artificial intelligence(AI) application intended to supercharge personalized content. The company provides industry-first self-serve solutions by uniting content velocity with brand personalization and control for completing lifecycle content development, enabling enterprises to create exceptional, on-brand content faster and easier and employees to craft captivating content with ease, speed, and brand authenticity.',
              ValuationTrack:
                '/strategyHoldersCompanies/2023/3/typeface/valuation_track.svg',
            },
          ],
        },
        {
          quarter: 4,
          items: [
            {
              title: 'Armada',
              index: 3,
              logo: '/strategyHoldersCompanies/2023/4/armada/logo.png',
              country: 'United States',
              businessType: 'TeleCom',
              countryCode: CountryCode.UnitedStates,
              website: 'www.armada.ai',
              companyDescription:
                'Developer of an edge computing platform designed to revolutionize connectivity, computing, and artificial intelligence (AI) tools. The company builds and operates a resilient, secure, and scalable infrastructure, with a matching software component, providing users with a single control plane to efficiently manage and optimize data.',
              ValuationTrack:
                '/strategyHoldersCompanies/2023/4/armada/valuation_track.svg',
            },
            {
              title: 'Kindred',
              index: 7,
              logo: '/strategyHoldersCompanies/2023/4/kindred/logo.png',
              country: 'United States',
              businessType: 'PropTech',
              countryCode: CountryCode.UnitedStates,
              website: 'www.livekindred.com',
              companyDescription:
                'Operator of a travel company intended to make a trusted network for exchanging homes. The company offers a home-swapping network and travel community that allows members to live a travel-rich lifestyle for a fraction of the cost of a hotel or vacation rental, enabling renters and homeowners to travel affordably.',
              ValuationTrack:
                '/strategyHoldersCompanies/2023/4/kindred/valuation_track.svg',
            },
            {
              title: 'LayerZero Labs',
              index: 10,
              logo: '/strategyHoldersCompanies/2023/4/layerzero_labs/logo.png',
              country: 'Canada',
              businessType: 'BlockChain',
              countryCode: CountryCode.Canada,
              website: 'www.layerzero.network',
              companyDescription:
                "Developer of blockchain interoperability protocol designed to connect disparate blockchains. The company's platform achieves speed, security, and cost-efficiency by performing validation as an on-chain light node but instead of keeping all block headers sequentially, block headers are streamed on-demand by decentralized oracles, providing clients with a cross-chain application with a low-level communication primitive.",
              ValuationTrack:
                '/strategyHoldersCompanies/2023/4/layerzero_labs/valuation_track.svg',
            },
            {
              title: 'Magic',
              index: 6,
              logo: '/strategyHoldersCompanies/2023/4/magic/logo.png',
              country: 'United States',
              businessType: 'DevOps, AI',
              countryCode: CountryCode.UnitedStates,
              website: 'www.magic.dev',
              companyDescription:
                "Operator of a software development company intended to build aligned and more complete AI. The company's platform develops reliable ways to align with human values, offers reinforcement learning that teaches AI through trial and error, and offers systems that typically pick up a vast amount of information during training and then use it during inference, enabling developers to find, consider, and reuse code.",
              ValuationTrack:
                '/strategyHoldersCompanies/2023/4/magic/valuation_track.svg',
            },
            {
              title: 'Spark Advisors',
              index: 11,
              logo: '/strategyHoldersCompanies/2023/4/spark_advisors/logo.png',
              country: 'United States',
              businessType: 'HealthTech',
              countryCode: CountryCode.UnitedStates,
              website: 'www.sparkadvisors.com',
              companyDescription:
                "Operator of a business management platform intended for Medicare insurance brokerages. The company's platform and services provide tools and resources and include features like enrollment and engagement tracking, marketing tools, and expert support, enabling brokers to better serve millions of Medicare beneficiaries and deliver better service to their clients.",
              ValuationTrack:
                '/strategyHoldersCompanies/2023/4/spark_advisors/valuation_track.svg',
            },
          ],
        },
      ],
    },
    {
      year: 2024,
      quarters: [
        {
          quarter: 1,
          items: [
            {
              title: 'Canva',
              index: 15,
              logo: '/strategyHoldersCompanies/2024/1/canva/logo.png',
              country: 'Australia',
              businessType: 'DesignTech',
              countryCode: CountryCode.Australia,
              website: 'www.canva.com',
              companyDescription:
                "Operator of an online graphic design platform intended to democratize design and visual communication access. The company's platform offers drag-and-drop features and professional layouts to consistently design graphics through a vast collection of professionally designed layouts to personalize the design with a stock library of photographs, illustrations, and imagery, enabling companies and users to customize presentations, and social media graphics with appropriate layouts.",
              ValuationTrack:
                '/strategyHoldersCompanies/2024/1/canva/valuation_track.svg',
            },
            {
              title: 'Celigo',
              index: 17,
              logo: '/strategyHoldersCompanies/2024/1/celigo/logo.png',
              country: 'United States',
              businessType: 'AutoTech',
              countryCode: CountryCode.UnitedStates,
              website: 'www.celigo.com',
              companyDescription:
                "Developer of a business process integration platform designed to create customized integrations using intuitive wizards. The company's platform features cloud-based application integration with its integrator and prebuilt connectors, which are full-featured and with customizable integrations, enabling clients to maximize the value, relevancy, and return on investment.",
              ValuationTrack:
                '/strategyHoldersCompanies/2024/1/celigo/valuation_track.svg',
            },
            {
              title: 'Knoetic',
              index: 10,
              logo: '/strategyHoldersCompanies/2024/1/knoetic/logo.png',
              country: 'United States',
              businessType: 'HRTech',
              countryCode: CountryCode.UnitedStates,
              website: 'www.knoetic.com',
              companyDescription:
                "Developer of people analytics software designed to allow enterprises to take important decisions about their workforce. The company's software integrates multiple sources of human resource data to become a single platform for all people analytics and visualizations, enabling human resource managers to make data-driven decisions by dealing with complex and unprecedented challenges daily.",
              ValuationTrack:
                '/strategyHoldersCompanies/2024/1/knoetic/valuation_track.svg',
            },
            {
              title: 'Mistral AI',
              index: 2,
              logo: '/strategyHoldersCompanies/2024/1/mistral_ai/logo.png',
              country: 'France',
              businessType: 'GenAI',
              countryCode: CountryCode.France,
              website: 'www.mistral.ai',
              companyDescription:
                'Developer of open-source artificial intelligence models designed to create a safe artificial general intelligence that benefits all users. The company offers customizable and compute-efficient large language models leveraging a community-driven development format, enabling customers to leverage the power of artificial intelligence without having to build and maintain their models.',
              ValuationTrack:
                '/strategyHoldersCompanies/2024/1/mistral_ai/valuation_track.svg',
            },
            {
              title: 'Perplexity',
              index: 4,
              logo: '/strategyHoldersCompanies/2024/1/perplexity/logo.png',
              country: 'United States',
              businessType: 'GenAI',
              countryCode: CountryCode.UnitedStates,
              website: 'www.perplexity.ai',
              companyDescription:
                "Developer of an artificial intelligence-based search engine platform designed to provide large language models and search engines. The company's platform allows the building of safe and beneficial artificial general intelligence and provides an open-source environment that is readily available to the public, enabling clients to develop skills and obtain knowledge in software development.",
              ValuationTrack:
                '/strategyHoldersCompanies/2024/1/perplexity/valuation_track.svg',
            },
          ],
        },
      ],
    },
  ],
};
