'use client';

import { useRouter } from 'next/navigation';
import { SelectedPage } from '@/types/types';
import { useAppSelector, useAppDispatch } from '@/hooks/reduxHooks';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { scroller } from 'react-scroll';

type Props = {
  page: string;
  offset?: number; 
};

export const BottomMenuLink = ({ page, offset = -40 }: Props) => { 
  const dispatch = useAppDispatch();
  const router = useRouter();
  const theme = useAppSelector((state) => state.theme.theme);
  const selectedPage = useAppSelector((state) => state.menuLinks.selectedPage);

  const themedText = theme === 'light' ? 'text-secondary-600' : 'text-gray-50';
  const lowerCasePage = page.toLowerCase().replace(/ /g, '') as SelectedPage;

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    dispatch(setSelectedPage(lowerCasePage));
    
    scroller.scrollTo(lowerCasePage, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset,
    });

    router.push(`#${lowerCasePage}`);
  };

  return (
    <span
      className={`text-xs lg:text-sm transition duration-500 hover:text-primary-200 cursor-pointer`}
      onClick={handleClick}
    >
      {page}
    </span>
  );
};
