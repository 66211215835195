import React from 'react';
import { useField } from 'formik';
import { Box } from '@mantine/core';
import { MyTextInputProps, formLabelStyle, textAreaStyle, inputFieldStyle } from './FormComponent';

export const MyTextInput: React.FC<
  MyTextInputProps & React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
> = ({ label, customStyles, as = 'input', ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Box className={`flex flex-col relative ${customStyles}`}>
      <label htmlFor={props.id || props.name} className={formLabelStyle}>
        {label}
      </label>
      {as === 'textarea' ? (
        <textarea
          className={`text-input transition-all ease-in-out delay-250 ${textAreaStyle}`}
          {...field}
          {...props} />
      ) : (
        <input
          className={`text-input ${inputFieldStyle}`}
          {...field}
          {...props} />
      )}
      {meta.touched && meta.error ? (
        <div className='error text-primary-500 absolute bottom-[-30px]'>
          {meta.error}
        </div>
      ) : null}
    </Box>
  );
};
