import React from 'react'
import { highlightText } from '@/utils/highlightText'

type SectionSubtitleProps = {
  subtitle: string;
  mt?: number;
  highlights?: string[];
}

export const SectionSubtitle = ({subtitle, mt = 12, highlights}: SectionSubtitleProps) => {
  return (
    <p className={`text-2xl font-base text-secondary-500 dark:text-lightBlue-200 mt-${mt} w-[70%] xxs:max-md:text-2xl xxs:max-md:w-full leading-normal`}>
      {highlightText(subtitle, highlights)}
    </p>
  )
}