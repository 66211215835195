export const theme = {
  'crosshair': {
    'line': {
      'stroke': '#ffffff',
      'strokeWidth': 1,
      'strokeOpacity': 0.45,
    },
  },
  "text": {
    "fontSize": 18,
    "fill": "#fff",
    "outlineWidth": 0,
    "outlineColor": "transparent"
  },
  "axis": {
    "domain": {
      "line": {
        "stroke": "#777777",
        "strokeWidth": 1
      }
    },
    "legend": {
      "text": {
        "fontSize": 14,
        "fill": "#b6cbda",
        "outlineWidth": 0,
        "outlineColor": "transparent"
      }
    },
    "ticks": {
      "line": {
        "stroke": "#777777",
        "strokeWidth": 1
      },
      "text": {
        "fontSize": 14,
        "fill": "#b6cbda",
        "outlineWidth": 0,
        "outlineColor": "transparent"
      }
    }
  },
  "grid": {
    "line": {
      "stroke": "#dddddd30",
      "strokeWidth": 1
    }
  },
  "legends": {
    "title": {
      "text": {
        "fontSize": 14,
        "fill": "#b6cbda",
        "outlineWidth": 0,
        "outlineColor": "transparent"
      }
    },
    "text": {
      "fontSize": 14,
      "fill": "#b6cbda",
      "outlineWidth": 0,
      "outlineColor": "transparent"
    },
    "ticks": {
      "line": {},
      "text": {
        "fontSize": 10,
        "fill": "#333333",
        "outlineWidth": 0,
        "outlineColor": "transparent"
      }
    }
  },
  "annotations": {
    "text": {
      "fontSize": 13,
      "fill": "#333333",
      "outlineWidth": 2,
      "outlineColor": "#ffffff",
      "outlineOpacity": 1
    },
    "link": {
      "stroke": "#000000",
      "strokeWidth": 1,
      "outlineWidth": 2,
      "outlineColor": "#ffffff",
      "outlineOpacity": 1
    },
    "outline": {
      "stroke": "#000000",
      "strokeWidth": 2,
      "outlineWidth": 2,
      "outlineColor": "#ffffff",
      "outlineOpacity": 1
    },
    "symbol": {
      "fill": "#000000",
      "outlineWidth": 2,
      "outlineColor": "#ffffff",
      "outlineOpacity": 1
    }
  },
  "tooltip": {
    "wrapper": {},
    "container": {
      "background": "#20384d90",
      "color": "#ffffff",
      "fontSize": 16
    },
    "basic": {},
    "chip": {},
    "table": {},
    "tableCell": {},
    "tableCellValue": {}
  }
}