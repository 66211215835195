import React from 'react';
import { Box } from '@mantine/core';
type RootBoxProps = {
  children: React.ReactNode;
};

export const RootBox = ({ children }: RootBoxProps) => {
  return (
    <Box className='flex flex-col mt-40 xxs:max-md:mt-20 h-full xxs:mx-[20px] md:mx-[120px] lg:mx-[240px] z-20 relative'>
      {children}
    </Box>
  );
};
