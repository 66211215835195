import { Box } from '@mantine/core';
import React from 'react';

type SectionTitleProps = {
  title: string;
  index: number
};

export const SectionTitle = ({ title, index }: SectionTitleProps) => {
  return (
    <Box className='flex flex-col justify-center relative'>
      <h2 className='text-secondary-600 dark:text-lightBlue-200 text-4xl font-montserrat font-bold uppercase tracking-[0.2rem] relative'>
        {title}
      </h2>
      <Box className='flex md:-left-[80px] lg:-left-[200px] md:w-[40px] lg:w-[160px] absolute items-center xxs:max-md:hidden'>
        <p className='font-bold text-primary-500 text-5xl mr-[40px]'>0{index}</p>
        <hr className='border-primary-500/80 border-[1px]  rounded-l-full rounded-r-full w-full' />
      </Box>
    </Box>
  );
};
