import { Box } from '@mantine/core';
import LogoPrimary from '@/../public/Arcanis_Logo_Primary.png';
import Image from 'next/image';

export const MobileTitleBox = () => {
  return (
    <Box className='overflow-visible flex w-full h-auto text-secondary-500 dark:text-lightBlue-200'>
      <Box className='flex flex-col w-full h-full justify-center items-center gap-8'>
        <Image
          priority
          src={LogoPrimary}
          alt='Arcanis Capital Logo'
          className='h-6 w-auto object-cover '
          quality={100}
        />
        <p
          className='font-montserrat font-extrabold tracking-wide text-center uppercase'
          style={{
            fontSize: 'calc(16px + 6vw)',
            lineHeight: '1.2',
            margin: '0',
          }}
        >
          NEW PLAYBOOK <br /> FOR VENTURE INVESTING 
        </p>
        <p className='text-secondary-500/90 dark:text-lightBlue-200/90 text-xl text-center font-montserrat font-bold tracking-wide mx-7'>
          Creating{' '}<br />
          <span className='text-primary-500'>
            Systematic VC Strategies{' '}
          </span>{' '}
          
          based on{' '}
          <span className='text-primary-500'>
            VC Growth Indexes
          </span>{' '}
          with 
          complete coverage of{' '}
          <span className='text-primary-500'>
            Growth and Late Stage*
          </span>{' '}
          venture
        </p>
        
        <p className=' text-xs text-center leading-tight text-secondary-500/50 dark:text-lightBlue-200/30 mx-12'>
          *GLS - Growth and Late Stage VC includes all VC-backed companies
          worldwide exceeding $15M round size
        </p>
      </Box>
    </Box>
  );
};
