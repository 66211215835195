'use client';

import { useAppSelector } from '@/hooks/reduxHooks';
import { ActionButton } from '@/shared/ActionButton';
import { SelectedPage } from '@/types/types';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { Box, rem } from '@mantine/core';
import { FloatingShadow } from './FloatingShadow';

export const TitleBox = () => {
  const theme = useAppSelector((state) => state.theme.theme);

  const icon = (
    <BriefcaseIcon
      style={{ width: rem(14), height: rem(14), marginBottom: rem(2) }}
    />
  );

  return (
    <Box className='overflow-hidden flex w-full h-full'>
      <Box className='flex flex-col w-full h-full justify-center items-center'>
        <Box className='flex flex-col w-full justify-start items-start '>
          <FloatingShadow>
            <p>NEW PLAYBOOK</p>
          </FloatingShadow>
          <p className='dark:text-lightBlue-200 text-secondary-500 md:text-[4rem] lg:text-[5rem] xl:text-[5rem] font-montserrat font-extrabold tracking-wide text-left'>
            NEW PLAYBOOK
          </p>
          <p className='text-left text-secondary-500/90 dark:text-lightBlue-200/80 -mt-4 md:-mt-4 w-full md:text-[2.5rem] lg:text-[2.5rem] xl:text-[3rem] font-montserrat font-extrabold tracking-wide leading-snug'>
            FOR VENTURE INVESTING
          </p>
          <p className='text-secondary-500 dark:text-lightBlue-500 md:text-xl lg:text-xl text-left tracking-wide leading-snug mt-8'>
            Creating <span className='text-primary-500 font-semibold'>Systematic VC Strategies </span> <br/>
            based on <span className='text-primary-500 font-semibold'>VC Growth Indexes</span> with <br/>
            complete coverage of  <br/>
            <span className='text-primary-500 font-semibold'>Growth and Late Stage*</span> venture
          </p>
          <p className='mt-3 text-xs leading-tight text-secondary-500/60 dark:text-lightBlue-200/30 mb-12'>
            *GLS - Growth and Late Stage VC includes all VC-backed <br />{' '}
            companies worldwide exceeding $15M round size
          </p>

          <ActionButton href={SelectedPage.About}>Learn more</ActionButton>
        </Box>
      </Box>
    </Box>
  );
};
