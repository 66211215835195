'use client';

import { ReactNode } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

interface ParallaxWrapperProps {
  children: ReactNode;
}

const ParallaxWrapper: React.FC<ParallaxWrapperProps> = ({ children }) => {
  return (
    <ParallaxProvider>
      {children}
    </ParallaxProvider>
  );
};

export default ParallaxWrapper;
