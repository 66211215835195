'use client';

import { useEffect } from 'react';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { setIsTopOfPage } from '@/features/menuLinks/menuLinksSlice';
import { useInView } from 'react-intersection-observer';

export const TopRef: React.FC = () => {
  const dispatch = useAppDispatch();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(setIsTopOfPage(true));
    } else {
      dispatch(setIsTopOfPage(false));
    }
  }, [inView, dispatch]);

  return (
    <div ref={ref}></div>
  );
};
