'use client';

import React, { useEffect, useMemo } from 'react';
import { Box } from '@mantine/core';
import { RootBox } from '@/shared/RootBox';
import dynamic from 'next/dynamic';
import { SectionTitle } from '@/shared/SectionTitle';
import { useInView } from 'react-intersection-observer';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { SelectedPage } from '@/types/types';
import { useAppDispatch } from '@/hooks/reduxHooks';

const SliderCarousel = dynamic(
  () => import('./SliderCarousel').then((mod) => mod.SliderCarousel),
  { ssr: false }
);
const Description = dynamic(
  () => import('./Description').then((mod) => mod.Description),
  { ssr: false }
);
const SliderCarouselMobile = dynamic(
  () =>
    import('./mobile/SliderCarouselMobile').then(
      (mod) => mod.SliderCarouselMobile
    ),
  { ssr: false }
);
const DescriptionMobile = dynamic(
  () =>
    import('./mobile/DescriptionMobile').then((mod) => mod.DescriptionMobile),
  { ssr: false }
);
const SectionSubtitle = dynamic(
  () => import('@/shared/SectionSubtitle').then((mod) => mod.SectionSubtitle),
  { ssr: false }
);

const SliderControlsMobile = dynamic(
  () =>
    import('./mobile/SliderControlsMobile').then(
      (mod) => mod.SliderControlsMobile
    ),
  { ssr: false }
);

const SliderControls = dynamic(
  () =>
    import('@/components/Strategy/SliderControls').then(
      (mod) => mod.SliderControls
    ),
  { ssr: false }
);

import { StrategiesContent } from '@/types/contentTypes';
import { useMediaQuery } from '@mantine/hooks';

type StrategyProps = {
  content: StrategiesContent;
};

const Strategy = ({ content }: StrategyProps) => {
  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');
  const dispatch = useAppDispatch();
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(setSelectedPage(SelectedPage.Strategies));
    }
  }, [inView, dispatch]);

  const descriptionComponent = useMemo(
    () => <Description content={content} />,
    [content]
  );

  return (
    <Box className='w-full h-full relative' id='strategies' ref={ref}>
      <RootBox>
        <SectionTitle title='Strategies' index={4} />
        <SectionSubtitle subtitle='Arcanis creates continous systematic index-based VC Strategies with minimum human factor, GLS-wide coverage and stabilized returns' />
        {isOnLargeScreen ? (
          <Box className='flex justify-between mt-12'>
            <Box className='w-[55%] sm:h-30 lg:h-38 xxs:max-sm:mt-6'>
              <SliderCarousel />
            </Box>
            <Box className='w-[10%] sm:h-30 lg:h-38 xxs:max-sm:mt-6 justify-center mx-auto'>
              <SliderControls />
            </Box>
            <Box className=' flex justify-start md:w-[41%]  '>
              {descriptionComponent}
            </Box>
          </Box>
        ) : (
          <Box className='flex flex-col justify-between mt-12'>
            <Box className='flex w-full'>
              <Box className='w-[80%]'>
                <SliderCarouselMobile />
              </Box>
              <Box className='flex w-[20%] justify-end'>
                <SliderControlsMobile />
              </Box>
            </Box>
            <Box className='flex justify-start w-[70vw] relative'>
              <DescriptionMobile content={content} />
            </Box>
          </Box>
        )}
      </RootBox>
    </Box>
  );
};

const areEqual = (prevProps: StrategyProps, nextProps: StrategyProps) => {
  return (
    prevProps.content === nextProps.content
  );
};

Strategy.displayName = 'Strategy';

export default React.memo(Strategy, areEqual);
