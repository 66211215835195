'use client';

import { Popover, Button, TextInput, Box } from '@mantine/core';
import React, { useState } from 'react';
import { PasswordInput } from '@mantine/core';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { SelectedPage } from '@/types/types';
import { scroller } from 'react-scroll';

type Props = {
  children: React.ReactNode;
};

export const LoginPopover = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
 

  const handleLogin = () => {

    setError('');


    setTimeout(() => {

      setError('Invalid email or password');
    }, 1000);
  };

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    dispatch(setSelectedPage(SelectedPage.Contacts));
    
    scroller.scrollTo(SelectedPage.Contacts, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 40,
    });
  }

  return (
    <Popover width={300} trapFocus position='bottom' withArrow shadow='md' zIndex={1000}>
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown>
        <Box>
          <TextInput
            label='Email'
            placeholder='Email'
            size='xs'
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
          <PasswordInput
            label='Password'
            placeholder='*********'
            size='xs'
            mt='xs'
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
          />
        </Box>
        {error && <p className='text-red-500 text-xs mt-2'>{error}</p>}
        <Box className='mt-6 flex items-center justify-between'>
          <Button
            size='xs'
            variant='gradient'
            gradient={{ from: '#ccb162', to: '#bf9d3b', deg: 40 }}
            radius={8}
            onClick={handleLogin}
          >
            Login
          </Button>
          <a className='text-xs text-secondary-200 underline cursor-pointer' onClick={handleClick}>Forgot your password?</a>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
