'use client';

import Image from 'next/image';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import LogoLight from '@/../public/Arcanis_Logo_White.png';
import LogoDark from '@/../public/Arcanis_Logo_Dark.png';
import { Box } from '@mantine/core';
import { AnchorMenuLink } from '@/shared/AnchorMenuLink';
import { useMediaQuery } from '@mantine/hooks';
import { useDisclosure } from '@mantine/hooks';
import { Burger } from '@mantine/core';
import { ActionButton } from '@/shared/ActionButton';
import { LoginPopover } from '@/shared/LoginPopover';
import { useAppSelector } from '@/hooks/reduxHooks';
import { useRef, useState, useEffect } from 'react';
import { SelectedPage } from '@/types/types';
import { ThemeSwitcher } from '@/features/themeSwitcher/ThemeSwitcher';

type Props = {};

const Navbar = (props: Props) => {
  const consentGiven = useAppSelector(
    (state) => state.userConsent.consentGiven
  );

  const menuItems = [
    'Home',
    'Products',
    'Indexes',
    'Strategies',
    'Strategy Holders',
    'Securitization',
    'Team',
    'Contacts',
  ];

  const isTopOfPage = useAppSelector((state) => state.menuLinks.isTopOfPage);
  
  const theme = useAppSelector((state) => state.theme.theme);
  const flexBetween = 'flex items-center justify-between';
  
  const [opened, { toggle }] = useDisclosure();
  const navBg =
    isTopOfPage && !opened
      ? ''
      : 'bg-secondary-50/90 dark:bg-secondary-500/80 drop-shadow';

  const navRef = useRef<HTMLDivElement>(null);
  const [navHeight, setNavHeight] = useState(0);

  useEffect(() => {
    if (navRef.current) {
      setNavHeight(navRef.current.offsetHeight);
    }
  }, []);

  return (
    <nav>
      <Box className='relative' style={{ zIndex: 999 }}>
        <Box
          ref={navRef}
          className='flex flex-col w-full fixed top-0'
          style={{ zIndex: 999 }}
        >
          <Box
            className={`${flexBetween} top-0 h-auto w-full py-3 whitespace-nowrap relative`}
          >
            <Box className={`${flexBetween} mx-8 xxs:max-md:mx-4 w-full`}>
              <Box className={`${flexBetween} w-full gap-16`}>
                {/* LEFT */}
                <Box className='flex w-full gap-6 items-center'>
                  <Burger
                    opened={opened}
                    onClick={toggle}
                    aria-label='Toggle navigation'
                    color={theme === 'light' ? '#4b677b' : '#FFF'}
                    className='z-50'
                  />
                  <Image
                    priority
                    src={theme === 'light' ? LogoDark : LogoLight}
                    alt='Arcanis Capital Logo'
                    className='h-6 w-auto object-cover -translate-y-1 xxs:max-md:hidden'
                    quality={100}
                  />
                </Box>
              </Box>
              {/* RIGHT */}
              <Box className='text-secondary-600 dark:text-gray-50 flex items-center justify-between gap-6 text-sm transition-transform duration-500 xxs:max-md:hidden'>
                <ThemeSwitcher />
                <LoginPopover>
                  <Box className='hover:text-primary-400 flex items-center justify-between gap-2'>
                    <UserCircleIcon className='w-4' />
                    <p className='cursor-pointer'>Sign In</p>
                  </Box>
                </LoginPopover>

                <ActionButton href={SelectedPage.Contacts}>
                  Contact Us
                </ActionButton>
              </Box>
            </Box>
            <Box
              id='animatedBox'
              className={`${navBg} absolute inset-0 -z-20 `}
            ></Box>
          </Box>
        </Box>
        <Box
          id='burgerMenu'
          className={`fixed left-0 w-80 bg-secondary-50/90 dark:bg-secondary-500/90 drop-shadow-xl border-t-lightBlue-500/30 border-t-[1px] transition-transform duration-500 ${
            opened ? 'translate-x-0' : '-translate-x-full'
          }`}
          style={{
            top: navHeight,
            height: `calc(100vh - ${navHeight}px)`,
            zIndex: 998,
          }}
        >
          <Box className='flex flex-col p-12 gap-8 h-full'>
            <Box className='flex flex-col text-xl gap-8'>
              {menuItems.map((menuItem, index) => (
                <AnchorMenuLink key={index} page={menuItem} />
              ))}
            </Box>
            <ThemeSwitcher />
          </Box>
        </Box>
      </Box>
    </nav>
  );
};

export { Navbar };
