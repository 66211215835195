'use client';

import { Box, Modal, ScrollArea, List } from '@mantine/core';
import { giveConsent } from '@/features/userConsent/userConsentSlice';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import { StandardButton } from '@/shared/StandardButton';
import { useFocusTrap, useMediaQuery } from '@mantine/hooks';

export const UserConsentModal = () => {
  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');
  const focusTrapRef = useFocusTrap();

  const consentGiven = useAppSelector(
    (state) => state.userConsent.consentGiven
  );
  const dispatch = useAppDispatch();

  const handleAccept = () => {
    dispatch(giveConsent(true));

  };

  

  return (
    <Modal
      opened={!consentGiven}
      onClose={() => {}}
      withCloseButton={false}
      centered
      size={isOnLargeScreen ? '50%' : '90%'}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 5,
      }}
      style={{}}
      classNames={{
        content: 'normal-shape-background',
      }}
      styles={{
        content: {
          borderRadius: '16px',
          zIndex: 1000,
        },
        overlay: {
          zIndex: 999,
        },
        inner: {
          zIndex: 1000,
        },
      }}
    >
      <Box
        className='w-full h-full text-lightBlue-200 p-5'
        ref={focusTrapRef}
      >
        <h1 className='text-xl font-bold'>LEGAL NOTICE</h1>
        <ScrollArea
          h={`50vh`}
          scrollbarSize={6}
          className='mt-4 mb-8 pr-4'
          styles={{
            thumb: {
              color: '#ffffff',
            },
            scrollbar: {
              color: '#ffffff',
            },
          }}
        >
          <p className='mt-4'>
            {`The information contained in this website is not intended and should
            not be used or construed as an offer to sell, or a solicitation of
            any offer to buy, securities of any fund or other investment product
            in any jurisdiction. No such offer or solicitation may be made prior
            to the delivery of definitive offering documentation. The
            information in this web site is not intended and should not be
            construed as investment, tax, legal, financial or other advice. By
            entering this site you duly and validly confirm that you fully meet
            the qualified investors’ criteria and have the right to view the
            information on this website (including any information regarding
            investment products).`}
            <h2 className='mt-4 font-bold'>GENERAL RISK DISCLOSURE</h2>
          </p>
          <p className='mt-2'>
            {`There are substantial risks in investing in any fund as may be
            specified in this website or using any of the services. Persons
            interested in investing in a fund should carefully note the
            following: `}
          </p>

          <List
            type='unordered'
            styles={{
              item: {
                marginTop: 12,
                paddingRight: 42,
              },
            }}
          >
            <List.Item>
              {`• A fund represents a speculative investment and involves a high
              degree of risk. An investor could lose all or a substantial
              portion of his/her investment. Investors must have the financial
              ability, sophistication/experience and willingness to bear the
              risks of an investment in a fund.`}
            </List.Item>
            <List.Item>{`• An investment in a fund is not
            suitable or desirable for all investors. Only certain persons
            meeting certain additional eligibility criteria may invest in a
            fund. `}</List.Item>
            <List.Item>
              {`• A fund may employ leverage and other investment techniques,
            and such leverage and other investment techniques may result in
            increased volatility of the fund’s performance and increased risk of
            loss.`}
            </List.Item>
            <List.Item>
              {`• A fund may have limited or no operating history.`}
            </List.Item>
            <List.Item>
              {`• The investment manager of a fund may have certain discretionary
              authority over the fund’s assets.`}
            </List.Item>
            <List.Item>
              {`• A fund may invest in a limited number of securities or
              instruments, which could result in a limited degree of
              diversification and higher risk.`}
            </List.Item>
            <List.Item>
              {`• A fund generally involves a complex tax structure, which should be
              reviewed carefully. A fund’s investment strategy may cause delays
              in important tax information being sent to investors.`}
            </List.Item>
            <List.Item>
              {`• The management fees of a fund’s investment manager may be
              substantial regardless of whether the fund has a positive return,
              and will offset the fund’s profits.`}
            </List.Item>
            <List.Item>
              {`• There are likely to be a number of conflicts of interest or
              potential conflicts of interest in connection with an investment
              manager’s management of fund assets.`}
            </List.Item>
            <List.Item>
              {`• The above summary is not a complete list of the risks and other
              important disclosures involved in investing in funds. Before
              making any investment in a fund, investors are advised to
              thoroughly and carefully review offering documentation with their
              financial, legal and tax advisors to determine whether an
              investment is suitable.`}
            </List.Item>
          </List>

          <h2 className='mt-4 font-bold'>LIMITATION OF LIABILITY</h2>
          <p className='mt-2'>
            ARCANIS accepts no responsibility for the contents of the Website or
            the information or recommendations contained herein, which moreover
            may be changed without notice. ARCANIS assumes no responsibility for
            ensuring, and makes no warranty, that the functioning of the website
            will be uninterrupted or error-free.{' '}
          </p>
          <p className='mt-2'>
            ARCANIS assumes no responsibility for the consequences of e-mail
            messages regarding a ARCANIS (transaction) service, which either
            cannot be received or sent, are damaged, received or sent
            incorrectly, or not received or sent on time.{' '}
          </p>
          <p className='mt-2'>
            ARCANIS will not be liable for any loss or damage that may result
            from access to and use of the website.{' '}
          </p>
          <p className='mt-2'>
            ARCANIS is not answerable for the accuracy or completeness of the
            facts, opinions, expectations and results referred to therein.
            Whilst every care has been taken in the preparation of this website,
            we do not accept any responsibility for damage of any kind resulting
            from incorrect or incomplete information. This website is subject to
            change without notice. The information contained on this website
            with respect to financial products or financial instruments may
            reflect a situation as per a specific date only and such situation
            can or will therefore be subject to changes. Please refer to the
            prospectus of the funds, the key investor information document or
            information memorandum made available for further details. The
            prospectus is available at the ARCANIS offices.
          </p>

          <h2 className='mt-4 font-bold'>INDEMNITIES</h2>
          <p className='mt-2'>
            You will indemnify ARCANIS and hold it harmless with respect to any
            liabilities (including all costs of investigation and defense) and
            other damages that directly or indirectly may derive from the
            failure to comply with the legal notice provisions, including, but
            not limited to, any liability or damage that directly or indirectly
            may derive from any use of arcaniscapital.com to make investment
            decisions or any breach by you or by any of your agents or delegates
            of these legal notice provisions.
          </p>
        </ScrollArea> 
        <Box className='flex xxs:max-md:flex-col xxs:max-md:gap-6 md:gap-12 items-center'>
        <StandardButton onClick={handleAccept} disabled={false}>
          Accept
        </StandardButton>
        <p className='text-lightBlue-200/40 text-xs md:text-sm'>If you do not accept the Legal Notice,<br/> please close this website</p>
        </Box>
      </Box>
    </Modal>
  );
};
