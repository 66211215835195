'use client'

import { Box } from '@mantine/core';
import React from 'react';
type Props = {
  children: React.ReactNode;
};
import { useAppSelector } from '@/hooks/reduxHooks';

export const BottomWrapper = ({ children }: Props) => {
  const theme = useAppSelector((state) => state.theme.theme);
  return (
    <Box
      className={`relative z-50 w-full h-full normal-shape-${theme === 'light' ? 'light-' : ''}background`}
      style={{
        width: '100%',
        height: '100%',
        overflow: 'visible',
      }}
    >
      <Box className='flex flex-col w-full '>{children}</Box>
    </Box>
  );
};
