'use client';

import { SelectedPage } from '@/types/types';
import { useMediaQuery } from '@mantine/hooks';
import { Box } from '@mantine/core';
import Image from 'next/image';
import { TitleBox } from './TitleBox/TitleBox';
import { MobileTitleBox } from './TitleBox/MobileTitleBox';
import { useEffect } from 'react';
import { useParallax } from 'react-scroll-parallax';

import MobileBG from '@/../public/bg/MobileBG2.png';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import { useInView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';

const MeshShapePrimaryReduced = dynamic(
  () => import('@/../public/bg/meshShapePrimaryReduced.svg'),
  { ssr: false }
);
const HeroImageChartNarrowSVGLG = dynamic(
  () => import('@/../public/bg/HeroImageChartNarrowLG.svg'),
  { ssr: false }
);

const HeroImageChartNarrowSVGLGLight = dynamic(
  () => import('@/../public/bg/HeroImageChartNarrowLGLight.svg'),
  { ssr: false }
);

export const HeroSection = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');

  const { ref: bgMeshRef } = useParallax<HTMLDivElement>({
    speed: 15,
    scale: [0.7, 1.2],
    rotate: [0, 12],
  });

  const dispatch = useAppDispatch();
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(setSelectedPage(SelectedPage.Home));
    }
  }, [inView, dispatch]);

  return (
    <Box
      className={
        isOnLargeScreen
          ? `hero-section-${theme === 'light' ? 'light-' : ''}background`
          : `mobile-hero-section-${theme === 'light' ? 'light-' : ''}background`
      }
      ref={ref}
      id='home'
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        zIndex: 50,
      }}
    >
      {isOnLargeScreen ? (
        <div
        className='left-0 ml-0'
          ref={bgMeshRef}
          style={{
            position: 'absolute',
            left: '-40vw',
            top: '10vh',
            width: '100%',
            height: '100%',
            zIndex: 1,
            rotate: '0deg',
            overflow: 'visible',
          }}
        >
          <Box className='opacity-15 w-full h-full'>
            <MeshShapePrimaryReduced />
          </Box>
        </div>
      ) : (
        <div ref={bgMeshRef} style={{position: 'absolute'}}></div>
      )}

      <Box
        id='heroimage'
        className='hidden md:flex absolute w-2/3 h-full right-0 justify-end items-center'
      >
        {theme === 'light' ? <HeroImageChartNarrowSVGLGLight /> : <HeroImageChartNarrowSVGLG />}
      </Box>
      <Box
        className='h-full'
        style={{
          position: 'relative',
          zIndex: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box className='flex overflow-visible xxs:mx-[20px] md:mx-[120px] w-full justify-left items-start h-full xxs:max-md:items-center xxs:max-md:mt-0'>
          <Box className='flex flex-col relative w-full h-full justify-start xxs:max-md:justify-center'>
            {isOnLargeScreen ? <TitleBox /> : <MobileTitleBox />}
            <Image
              src={MobileBG}
              alt='Chart Hero Image'
              className='md:hidden'
              fill
              quality={100}
              style={{
                objectFit: 'cover',
                overflow: 'visible',
                opacity: 1,
                zIndex: -1,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
