'use client';

import Link from 'next/link';
import React from 'react';
import { SelectedPage } from '../types/types';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { setSelectedPage } from '@/features/menuLinks/menuLinksSlice';

type Props = {
  children: React.ReactNode;
  href: SelectedPage;
};

export const ActionButton = ({ children, href }: Props) => {
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); 

    dispatch(setSelectedPage(SelectedPage.Contacts));

    const targetElement = document.getElementById(href);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
    window.history.pushState(null, '', `#${href}`);
  };

  return (
    <a
      className='text-gray-50 bg-gradient-to-r from-primary-500 to-primary-600 rounded-lg px-6 py-2 hover:from-primary-300 hover:to-primary-400 hover:text-gray-50 font-semibold'
      onClick={handleClick}
      href={`#${href}`}
    >
      {children}
    </a>
  );
};
