import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, LoadingOverlay } from '@mantine/core';
import { MyTextInput } from './MyTextInput';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { giveConsent } from '@/features/userConsent/userConsentSlice';
import CustomSelect  from './CustomSelect';

export interface MyTextInputProps {
  label: string;
  name: string;
  customStyles?: string;
  as?: 'input' | 'textarea';
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  message: string;
  action: string;
}

interface FormComponentProps {
  onFormSubmit: (status: string) => void;
}

export const inputFieldStyle =
  'flex bg-lightBlue-500/40 rounded-md text-secondary-500 dark:text-lightBlue-200 h-10 text-lg indent-2 outline-offset-0 outline-primary-500 invalid:border-primary-500 invalid:text-primary-200';
export const textAreaStyle =
  'flex items-center bg-lightBlue-500/40 rounded-md text-secondary-500 dark:text-lightBlue-200 text-lg pl-2 pt-1.5 outline-offset-0 outline-primary-500 invalid:border-primary-500 invalid:text-primary-200 h-10 focus:h-32';
export const formLabelStyle = 'text-secondary-400 dark:text-lightBlue-500 mb-4';

export const FormComponent: React.FC<FormComponentProps> = ({ onFormSubmit }) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(15, 'Must be 15 characters or less')
      .required('Required'),
    lastName: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .required('Required'),
    company: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .required('Required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
    message: Yup.string().max(400, 'Must be 400 characters or less'),
    action: Yup.string().required('Required'),
  });

  const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  return (
    <Box className='relative'>
      <LoadingOverlay
        visible={loaderVisible}
        zIndex={1000}
        overlayProps={{ radius: "lg", blur: 4, backgroundOpacity: 0.08 }}
        loaderProps={{ color: 'white', type: 'dots' }}
        style={{
          margin: -16,
        }} />
      <Formik<FormValues>
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          message: '',
          action: '',
        }}
        validationSchema={validationSchema}

        onSubmit={async (values, { setSubmitting }) => {
          setLoaderVisible(true);
          try {
            const response = await fetch('/api/submitForm', {
              method: 'POST',
              body: JSON.stringify(values),
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (response.ok) {
              onFormSubmit('submitted');
              console.log('Form submitted');
            } else {
              const responseText = await response.text();
              console.error('Failed to submit form', response.status, response.statusText, responseText);
              onFormSubmit('error');
            }
          } catch (error) {
            console.error('Error submitting form', error);
            onFormSubmit('error');
          } finally {
            setSubmitting(false);
            setLoaderVisible(false);
          }
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form className='w-full'>
            <Box className='flex w-full gap-8 xxs:max-md:flex-col'>
              <MyTextInput
                label='First Name*'
                name='firstName'
                type='text'
                placeholder='John'
                customStyles='w-1/3 xxs:max-md:w-full'
              />

              <MyTextInput
                label='Last Name*'
                name='lastName'
                type='text'
                placeholder='Doe'
                customStyles='w-1/3 xxs:max-md:w-full'
              />

              <MyTextInput
                label='Company*'
                name='company'
                type='text'
                placeholder='Company'
                customStyles='w-1/3 xxs:max-md:w-full'
              />
            </Box>
            <Box className='flex xxs:max-md:flex-col mt-12 xxs:max-md:mt-8 gap-8 w-full'>
              <MyTextInput
                label='Email Address*'
                name='email'
                type='email'
                placeholder='johndoe@email.com'
                customStyles='w-1/3 xxs:max-md:w-full'
                
              />
              <MyTextInput
                label='Message'
                name='message'
                as='textarea'
                placeholder='Your message'
                customStyles='w-2/3 xxs:max-md:w-full'
                
              />
            </Box>
            {/* LAPTOP+ */}
            <Box className='flex mt-20 xxs:max-md:hidden'>
              <Box className='flex flex-col w-1/6'>
                <button
                  type='submit'
                  className='bg-primary-500 hover:bg-primary-400 text-white px-4 py-2 rounded-lg font-semibold'
                  disabled={isSubmitting}
                >
                  Submit
                </button>
                <p className='lg:text-xs xl:text-sm text-lightBlue-600 mt-4'>
                  By clicking Submit you agree with <span className='underline cursor-pointer' onClick={() => dispatch(giveConsent(false))}>Arcanis Legal Notice</span>
                </p>
              </Box>
              <Box className='flex flex-col w-1/2 ml-12 gap-2 relative overflow-visible'>
              <CustomSelect
                  name='action'
                  label='I would like to*'
                  placeholder='Pick value'
                  data={[
                    { value: 'Learn more about Arcanis data', label: 'Learn more about Arcanis data' },
                    { value: 'Invest in Arcanis', label: 'Invest in Arcanis' },
                    { value: 'Become a distribution partner', label: 'Become a distribution partner' },
                    { value: 'Discover partnership opportunities', label: 'Discover partnership opportunities' },
                  ]}
                />
                {errors.action && (
                  <div className='error text-primary-500 absolute bottom-10'>
                    {errors.action}
                  </div>
                )}
              </Box>
            </Box>
            {/* MOBILE */}
            <Box className='flex flex-col mt-16 md:hidden relative'>
              <Box className='flex flex-col gap-2'>
              <CustomSelect
                  name='action'
                  label='I would like to*'
                  placeholder='Pick value'
                  data={[
                    { value: 'Learn more about Arcanis data', label: 'Learn more about Arcanis data' },
                    { value: 'Invest in Arcanis', label: 'Invest in Arcanis' },
                    { value: 'Become a distribution partner', label: 'Become a distribution partner' },
                    { value: 'Discover partnership opportunities', label: 'Discover partnership opportunities' },
                  ]}
                />
                {errors.action && (
                  <div className='error text-primary-500'>
                    {errors.action}
                  </div>
                )}
              </Box>
              <Box className='flex flex-col w-full mt-12'>
                <button
                  type='submit'
                  className='bg-primary-500  text-white px-4 py-2 rounded-lg font-semibold '
                  disabled={isSubmitting}
                >
                  Submit
                </button>
                <p className='lg:text-xs xl:text-sm text-lightBlue-600 mt-4 text-center'>
                  By clicking Submit you agree with <span className='underline' onClick={() => dispatch(giveConsent(false))}>Arcanis Legal Notice</span>
                </p>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
