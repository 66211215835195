import React from "react";

export const highlightText = (
  description: string,
  highlights: string[] | undefined,
  highlightClassname = 'font-semibold'
): JSX.Element[] => {
  if (!highlights) {
    return [<span key="description">{description}</span>];
  }
  const safeHighlights = highlights.map((h) =>
    h.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  );
  const regex = new RegExp(`(${safeHighlights.join('|')})`, 'gi');
  const parts = description.split(regex);

  return parts.map((part, index) => {
    if (safeHighlights.some((h) => h.toLowerCase() === part.toLowerCase())) {
      return (
        <span key={index} className={highlightClassname}>
          {part}
        </span>
      );
    }
    return <React.Fragment key={index}>{part}</React.Fragment>;
  });
};