'use client';

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import { toggleTheme } from './themeSwitcherSlice';
import { Switch, rem } from '@mantine/core';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';

export const ThemeSwitcher = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const [checked, setChecked] = useState(theme === 'light');
  const dispatch = useAppDispatch();
  
  const sunIcon = (
    <SunIcon
      style={{ width: rem(16), height: rem(16) }}
      className='text-secondary-100'
    />
  );
  const moonIcon = (
    <MoonIcon
      style={{ width: rem(16), height: rem(16) }}
      className='text-secondary-400'
    />
  );

  const switchStyles = {
    thumb: {
      backgroundColor: checked ? '#a6afb8' : '#4D6983',
      borderColor: checked ? '#B0B9C2' : '#4d6071',
    },
    track: {
      backgroundColor: checked ? '#DFE5E9' : '#144f6c',
      borderColor: checked ? '#B0B9C2' : '#4d6071',
    },
  };

  useEffect(() => {
    document.documentElement.classList.remove('light', 'dark');
    document.documentElement.classList.add(theme);
  }, [theme]);

  useEffect(() => {
    setChecked(theme === 'light');
  }, [theme]);

  return (
    <Switch
      checked={checked}
      size='sm'
      styles={switchStyles}
      onLabel={moonIcon}
      offLabel={sunIcon}
      onChange={(event) => {
        setChecked(event.currentTarget.checked);
        dispatch(toggleTheme());
      }}
    />
  );
};
